import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
  document.getElementsByTagName('title')[0].innerHTML=(window.location.hostname=== "localhost")?'localhost':'IoT,AI & ML Solutions';

 // console.log(hostname,'first',title);
  
