import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CommonmethodsService {
  actionPageJson;
  actionDetailView = 0;
  trackingVersionCall=1;
  maintainTrackversion;
  jqxtreeDataArray=[];
  constructor(private AjaxCall:AjaxService,private commonservice:CommonService) {}
  setgridColumn(array: string[][], dividewidth = 0, customFun?: Function) {
    //arg[0]=height of  grid,
    let setwidth: number;
    let column: jqwidgets.GridColumn[] = [];
    if (dividewidth) setwidth = Math.round(100 / array.length);
    array.forEach((x, i) => {
      //gridcolArray ----> 0=> value will bind later, 1 =>text,2=>column group,3=>type,4=>width,5=> calculation if
      let gridcolArray = x[1].split(','),
        heading = gridcolArray[1];
      let colobj: jqwidgets.GridColumn = {
        text: heading,
        datafield: x[0],
        width:(setwidth)? `${setwidth}%` : gridcolArray[4],
        align: 'center',
        cellsalign:'center'
      };
      column.push(colobj);
      customFun(x, i, column, gridcolArray);
    });

    // console.log(column);
    return column;
  }
  preventiveHit(){
    
  };
    trackVersionCheck(fromwt='here'){
      let confirmation;
      let askpermission=()=>{
        if(this.trackingVersionCall){
          confirmation=confirm('Kindly Logout New Version Available Do you Want to Update ?');
        }
       
       if(confirmation){
        this.commonservice.signOut('?id=reload');
        // document.location.reload();
       }else{
        this.commonservice.presentToast('Kindly Click Logout Button To Update Latest Version')
        this.trackingVersionCall=0
       }
      };

let checking={
  here_true:this.reloadApp,
  here_false:()=>{},
  there_true:askpermission,
  there_false:()=>{}
}
      let currenversion=localStorage.getItem('TrackVersion');
      // let currenversion='1';
        let params=new HttpParams().set('key','TrackVersion').set('companyID','VersionControl');
       this.AjaxCall.getPreferencesData(params,1).subscribe((res:String)=>{
        //  console.log(res,'trackversion'); 

     let doreload=`${fromwt}_${currenversion!=res}`;
     this.maintainTrackversion=res;
    checking[doreload]();
        },err=>{console.log( err,'trackversion')})
   
    };
  reloadApp=()=>{
    this.commonservice.presentToast('Updating Latest Version!!!');
    localStorage.setItem('TrackVersion',this.maintainTrackversion);
let see=setTimeout(() => {
  if ('caches' in window) {
    // Clear all cached data
    caches.keys().then((cacheNames)=> {
      cacheNames.forEach((cacheName)=> {
        caches.delete(cacheName);
      });
    });
  }
  // Perform a hard reload
  location.reload();
  // document.location.reload();
  clearTimeout(see);
 }, 1000);
    };
  getFuelPercentageKey_DD(_siteInformation,loginType:string):string{
    let propname=''
    if (_siteInformation['fuelInput'] == 'Yes'&& loginType=='Generator_Fuel') {
      return  propname='DGliterPercentage,1,1';
    } else {
      let fuelpropertyJson={
        Generator_Fuel:'siteType',
        Hybrid:'phase'
      };
      let props={
        dgOnly:'fuelLeval,1,1',
        fuelDG:'levelPercentage,10,1',
        'I Phase':'fuelLeval,1,1',
        'III Phase':'fuelLeval,1,1'
      }
      let fuelcalc=fuelpropertyJson[loginType];
    return props[_siteInformation[fuelcalc]] ||'fuelLeval';
  }
}
}
